{
    function Storage() {

        this.get_local = function(name) {
            return JSON.parse(localStorage.getItem(name));
        };

        this.get_session = function (name) {
            return JSON.parse(sessionStorage.getItem(name));
        };

        this.remove_local = function (name) {
            localStorage.removeItem(name);
        };

        this.remove_session = function (name) {
            sessionStorage.removeItem(name);
        };

        this.set_local = function(name, value) {
            localStorage.setItem(name, JSON.stringify(value));
        };

        this.clear_local = function() {
            localStorage.clear();
        };

        this.clear_session = function() {
            sessionStorage.clear();
        };
    }
}