var save_attempts = 0;
var delay = (function(){
  var timer = 0;
  return function(callback, ms){
    clearTimeout (timer);
    timer = setTimeout(callback, ms);
  };
})();
var elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));
elems.forEach(function(html) {
	var opts = {
		size: 'small',
		color: '#2797DB'
	};
	var switchery = new Switchery(html, opts);
});
function cloned(obj) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }
    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = cloned(obj[i]);
        }
        return copy;
    }
    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = cloned(obj[attr]);
        }
        return copy;
    }
    throw new Error("Unable to copy obj! Its type isn't supported.");
}
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}
function getCookie(cname) {
	var name = cname + "=";
	var ca = document.cookie.split(';');
	for(var i=0; i<ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') c = c.substring(1);
		if (c.indexOf(name) == 0) return c.substring(name.length,c.length);
	}
	return "";
}

function isValidEmailAddress(emailAddress) {
	var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
	return pattern.test(emailAddress);
};



var timeoutHandle;
var countless = new function() {
	this.init = function(){
		if(typeof login != "boolean") { this.displayUserMessages(); }
	},
	/********************************************/
	this.updateUserConsumption = function(){
		$.ajax({
		    url : 'ajax/user-consumption-remaining.php',
			dataType : 'json',
		    success: function(data){
			    $.each(data, function(key, value){
					$('.daily-' + key + ' .num').countTo({
					    from: parseFloat($('.daily-' + key + ' .num').text()),
					    to: value,
					    speed: 1000,
					    refreshInterval: 50,
					    decimals: 1,
					    formatter: function(value, options) {
							return value.toFixed(options.decimals).replace(/\.?0+$/, '');
						},
					    onUpdate: function(val){
						    if(val < 0){
							    $(this).addClass( 'red' );
						    } else {
							    $(this).removeClass('red');
						    }
					    },
					    onComplete: function(val){
						    parent = $(this).parent();
						    if(parent.is('.daily-veggies')){
							    if(val <= 0){
								    parent.find('.num').hide();
								    if(parent.find('.achieved').length === 0){
								    	parent.find('.label').prepend('<div class="achieved">Goal <br />Achieved</div>');
								    }
								    $('.achieved').css('margin-top',($('.daily-moderations').height()-$('.daily-veggies').height()) + 'px');

							    }
						    }
					    }
					});

			    });
			}
		});
	},
	/*********************************************/
	this.displayUserMessages = function (){
		$.ajax({
		    url : 'ajax/user-messages.php',
			dataType : 'json',
		    success: function(data){
			$.each(data, function(key,item){
				if($('.message[data-id="' + item.ID + '"]').length == 0){
					var dismissable = (item.display_type == 'dismissed') ? true : false;
					msg = $('<div class="message ' + item.msg_type + ' ' + ((dismissable) ? 'dismissable' : '') + '" data-id="' + item.ID + '">' + item.msg + ((dismissable) ? '<a href="#" class="dismiss"><i class="icon-x"></i></a>' : '') + '</div>');
					msg.hide();
					$('#container-messages').append(msg);
					msg.slideDown();
				}
			});
		    }
		});
	},
	/*********************************************/
	this.create_food_items = function(opts){
		var opts = opts || {};
		var items = opts.items || [];
		var results_container = opts.results_container || false;
		var meal_container = opts.meal_container || $('#meal-contents');
		var add_x = opts.add_x || false;
		var calculate = opts.calculate || false;
		var add_plus = opts.add_plus || false;
		var add_minus = opts.add_minus || false;
		var item_classes = opts.item_classes || '';
		var save = opts.save || false;
		var $countless = this;

		$.each(items, function(index, item) {
			if(results_container && results_container.children('li').filter(function() { return $(this).data('itemid') === item.id}).length){
				var preexisting_item = results_container.find('li[data-itemid="' + item.id + '"]');
				var item_data = preexisting_item.data('item');
			  	item_data.qty = item_data.qty + 1;
			  	preexisting_item.data('item', item_data).find('.qty').show().find('.num').text(item_data.qty);
			} else {
				var elem = $('<li />')
					.data('item', item)
					.attr('data-itemid', item.id)
					.addClass(item_classes);
				var title = $('<div />')
					.addClass('title')
					.html(item.title)
					.appendTo(elem);
				if(item.portion != 'null' && item.portion != null){
					title.append(' <span class="portion">' + item.portion + '</span>');
				}
				switch(item.food_type){
					case 'macronutrient':
						points = 'Proteins: ' + item.protein + ' | Fats: ' + item.fats + ' | Carbs: ' + item.carbs;
						break;
					case 'veggie':
						points = 'Veggies: ' + item.veggies;
						break;
					case 'moderation':
						points = 'Moderations: <span class="num">' + item.moderations + '</span>';
						break;
					default:
						points = '';
						break;
				}
				$('<div />')
					.addClass('values')
					.html('<span class="color-rating ' + (item.rating_name === 'N/A' ? 'na' : item.rating_name.toLowerCase()) + '">' + item.rating_name + '</span> ' + points)
					.appendTo(elem);
				qty = $('<div />')
						.addClass('qty')
						.html('Qty: <span class="num">' + item.qty + '</span>')
						.appendTo(elem);
				if(item.qty == 1){ qty.hide(); }
				icons = $('<div />').addClass('icons');
				$.each(item.icons, function(index, icon) {
					$('<i />')
						.addClass('icon-' + icon)
						.appendTo(icons);
				});
				fav_icon = $countless.create_fav_icon(item);
				icons.append(fav_icon);
				if(add_x){
					var button = $countless.create_x_button(placeholder);
					icons.append(button);
				}
				if(add_plus){
					var button = $countless.create_plus_button(meal_container);
					icons.append(button);
				}
				if(add_minus){
					var button = $countless.create_minus_button(item);
					icons.append(button);
				}
				icons.appendTo(elem);
				elem.appendTo(results_container);
			}
			if(calculate){
				$countless.calculate_points(item, true, true);
			}
		});
		if(save){
			countless.save_meal({meal: meal_container});
		}
	},
	/*********************************************/
	this.create_x_button = function(placeholder){
		return $('<a />').attr('title', 'Remove Item from Meal').addClass('delete').html('<i class="icon icon-x"></i>').click(function(e){
	       e.preventDefault();

	        parent = $(this).parents('li');
	        meal = parent.parent();
			item = parent.data('item');
			countless.calculate_points(item, false)

			$('.points div .num').each(function(){
			  if(parseFloat($(this).text()) < 0){
			      $(this).addClass('red');
			  } else {
			      $(this).removeClass('red');
			  }
			});
			if(meal.children().length == 1){
				meal.append(placeholder);
			}
			parent.remove();
			countless.save_meal.bind(meal)();
	   });
	},
	/*********************************************/
	this.create_minus_button = function(item){
		return $('<a />').attr('title', 'Decrease Quantity').addClass('delete').html('<i class="icon icon-minus"></i>').click(function(e){
	       	e.preventDefault();

	       	parent = $(this).parents('li');
	       	meal = parent.parent();
	       	item.qty = item.qty - 1;
	       	parent.data('item', item);
	       	parent.find('.qty .num').text(item.qty);
	       	if(parent.parent().children().length == 1  && item.qty == 0){
				parent.parent().append(placeholder);
			}
			if(item.qty > 1){
				parent.find('.qty').show();
			} else if(item.qty > 0){
				parent.find('.qty').hide();
			}
			if(item.qty <= 0) {
				parent.remove();
			}
			countless.save_meal.bind(meal)();
			countless.calculate_points(item, false)

			$('.points div .num').each(function(){
			  if(parseFloat($(this).text()) < 0){
			      $(this).addClass('red');
			  } else {
			      $(this).removeClass('red');
			  }
			});
	   });
	},
	/*********************************************/
	this.create_plus_button = function(meal_container){
		$this = this;
		return $('<a />').addClass('add').attr('title', 'Add Item to Meal').html('<i class="icon icon-plus"></i>').click(function(e){
	       e.preventDefault();
	       if((save_attempts > 0) || save_attempts == 0){
			   if($( window ).width() < 1001){
				   $('#mobile-back-btn').click();
				   $('#query').val('').keyup();
				}
				item = cloned($(this).parents('li').data('item'));
				if (meal_container.find('li[data-itemid="' + item.id + '"]').length > 0){
			    	var preexisting_item = meal_container.find('li[data-itemid="' + item.id + '"]');
				  	var item_data = preexisting_item.data('item');
				  	item_data.qty = parseFloat(item_data.qty) + 1;
				  	preexisting_item.data('item', item_data).find('.qty').show().find('.num').text(item_data.qty);
				  	countless.save_meal.bind(preexisting_item.parent())();
				  	countless.calculate_points(item, true);
		      	} else {
	      		   	clone = $(this).parent().parent().clone();
	      			clone.data('item', item);
					countless.calculate_points(item, true);
					meal_container.children('#placeholder').remove();
					clone.find('.add').remove();
					clone.find('.icons').append($this.create_plus_button(meal_container));
					clone.find('.icons').append($this.create_minus_button(item));
					clone.appendTo(meal_container);
					countless.save_meal.bind(meal_container)();
					create_sortable();
				}
				$('.addBox').remove();
			}
	   });
	},
	/*********************************************/
	this.save_meal = function(opts){
		save_attempts++;
		var opts = opts || {};
		var meal = opts.meal || this;

		window.clearTimeout(timeoutHandle);
		items = [];
		meal.children('li').each(function(index, item){
			var item_data = $(item).data('item');
			items.push({id: item_data.id, qty: item_data.qty});
		});
		var title = opts.title || meal.data('meal-title') || $('#meal-title').val();
		var meal_id = opts.id === 0 ? 0 : opts.id || meal.data('meal-id');
		var fav = opts.fav === 0 ? 0 : opts.fav || meal.data('meal-fav');
		var dt = opts.dt === 0 ? 0 : opts.dt || meal.data('meal-dt');
		savingHtml = '<span class="status"><i class="fa fa-refresh fa-spin"></i> <span class="text">Saving</span></span>';
		if( meal.parent().find('h2 .status').length === 0 ) {
			$loading =  $(savingHtml).css('margin-left', '5px').appendTo(meal.parent().find('h2'));
		} else {
			$loading = meal.parent().find('h2 > span.status');
			$loading.html(savingHtml);
		}
		$.ajax({
		    url : 'ajax/meal-changes.php',
		    type: 'POST',
		    data : {
			    items: items,
			    title: title,
			    id: meal_id,
	            dt: dt,
	            fav: fav
		    },
			dataType : 'json',
		    success: function(data){
		    	if(data.fav === 0){
		    		meal.data('meal-id', data.id);
					meal.data('meal-title', title);
		    	}
				if($('#this-meal .message').length == 0){
					$loading.find('.text').text('Saved').parent().children('.fa').removeClass('fa-refresh fa-spin').addClass('fa-check')
				}
				timeoutHandle = window.setTimeout(function(){ $loading.remove(); }, 2000);
		    },
		    error: function (){
			    if($('#this-meal .message').length == 0){
			    	$loading.remove();
			    	$('<div />').addClass('message error').html('The meal could not be saved.').hide().insertAfter('#this-meal h2').fadeIn();
			    }
			    timeoutHandle = window.setTimeout(function(){ $('.message').fadeOut(function(){$(this).remove()}); }, 2000);
		    }
		});
	},
	/*********************************************/
	this.calculate_points = function(item, add_item, initialLoad){
		if(initialLoad){
			numbers = [
				['carbs', item.carbs * item.qty],
				['protein', item.protein * item.qty],
				['fats', item.fats * item.qty],
				['veggies', item.veggies * item.qty],
				['moderations', item.moderations * item.qty]
			];
		} else {
			numbers = [
				['carbs', item.carbs],
				['protein', item.protein],
				['fats', item.fats],
				['veggies', item.veggies],
				['moderations', item.moderations]
			];
		}
		if(add_item){
			$.each(numbers, function(key, number){
				$('.current-' + number[0] + ' .num').text(parseFloat($('.current-' + number[0] + ' .num').first().text()) + number[1]);
				// Don't allow Veggies to go negative
				if((number[0] != 'veggies') || ((number[0] == 'veggies') && $('.daily-' + number[0] + ' .num').text() != 0)){
					$('.daily-' + number[0] + ' .num').text(parseFloat($('.daily-' + number[0] + ' .num').first().text()) - number[1]);
				}
			});
			countless.goal_achieved();
		} else {
			$.each(numbers, function(key, number){
				$('.current-' + number[0] + ' .num').text(parseFloat($('.current-' + number[0] + ' .num').first().text()) - number[1]);
				// Don't allow Veggies to raise above max allowed for the day since we aren't counting 'negative' values
				if((number[0] != 'veggies') || ((number[0] == 'veggies') && $('.daily-' + number[0] + ' .num').text() != max_numbers.veggies)){
					$('.daily-' + number[0] + ' .num').text(parseFloat($('.daily-' + number[0] + ' .num').first().text()) + number[1]);
				}
			});
			if(($('.daily-veggies .num').text() != '0') && ($('.daily-veggies .num').text() != '00') && ($('.daily-veggies .label .achieved').length != 0)) {
				$('.daily-veggies .label .achieved').remove();
				$('.daily-veggies .num').show();
			}
		}

		$('.points div .num').each(function(){
		  if(parseFloat($(this).text()) < 0){
		      $(this).addClass('red');
		  } else {
		      $(this).removeClass('red');
		  }
		});
	},
	/*********************************************/
	this.goal_achieved = function(){
		if(($('.daily-veggies .num').first().text() == '0') && ($('.daily-veggies .label .achieved').length == 0)) {
			$achieved = '<div class="achieved">Goal <br />Achieved</div>';
			$('.daily-veggies .num').hide();
			if($( window ).width() < 1001){
				$('.daily-veggies .label').append($achieved);
			} else {
				$('.daily-veggies .label').prepend($achieved);
				$('.desktop-only .achieved').css('margin-top',($('.desktop-only .grid .daily-moderations').height()-$('.desktop-only .grid .daily-veggies').height()) + 'px');
			}

		}
	}
	/*********************************************/
	this.create_fav_icon = function(item){
		star = item.favorite ? "star" : "star-o";
		className = item.favorite ? 'favorite active' : 'favorite';
		return $('<a />').attr('title', 'Mark Favorite').addClass(className).html('<i class="fa fa-' + star + '"></i>')
	},
	/*********************************************/
	this.load_library = function(opts) {
		var opts = opts || {};
		var $query = opts.query || '';
		var $cat = opts.cat || 'all_cats';
		var $rating = opts.rating || 'All Ratings';
		var results_container = opts.results_container || $('#results');
		var meal_container = opts.meal_container || $('#meal-contents');
		var sortable = opts.sortable == 'undefined' ? true : opts.sortable;
		results_container.slideUp(function() {
			$.ajax({
				url: 'ajax/food-library.php',
				type: 'POST',
				data: {
					query: $query,
					cat: $cat,
					rating: $rating
				},
				dataType: 'json',
				success: function(data) {
					if (data.result == 'success') {
						results_container.empty();
						countless.create_food_items({
							items: data.items,
							results_container: results_container,
							calculate: false,
							add_plus: true,
							meal_container: meal_container || null
						});
						results_container.slideDown();
					} else {
						results_container.empty();
						$('<li />').addClass('noclick').html(data.message).prependTo(results_container);
						results_container.slideDown();
					}
					if(sortable){
						results_container.children().draggable({
						  connectToSortable: "#meal-contents",
						  helper: "clone",
						  revert: "invalid"
						});
					    if($( window ).width() < 1001){
						    toggleSortables('disable');
					    }
					}
				    $( "ul, li" ).disableSelection();
				},
				error: function() {
					results_container.empty();
					$('<li />').addClass('noclick').html('An error occurred while communicating with the server.').prependTo(results_container);
					results_container.slideDown();
				}
			});
		});
	},
	/*********************************************/
	this.generic_modal = function(content){
		$wrapper = $('<div />').addClass('dim-mask').hide();
		$modal = $('<div />').html(content).addClass('generic').appendTo($wrapper);
		$($wrapper, $modal).click(function(){
			$wrapper.fadeOut(function(){
				$(this).remove();
			})
		});
		$('body').append($wrapper);
		$wrapper.fadeIn();
		return $wrapper;
	},
	/*********************************************/
	this.prompt_modal = function(content){
		$wrapper = $('<div />').addClass('dim-mask').hide();
		$modal = $('<div />').html(content).addClass('generic').appendTo($wrapper);
		$wrapper.click(function(){
			$wrapper.fadeOut(function(){
				$(this).remove();
			})
		});
		$modal.click(function(e){
			e.stopPropagation();
		});
		$('body').append($wrapper);
		$wrapper.fadeIn();
		return $wrapper;
	},
	/*********************************************/
	this.colorScoreGraphPosition = function (score) {
        if (score < 80) {
	        return 16.5;
	    } else if (score <= 90) {
	        return 50;
	    } else {
			return 83.5;
	    }
	},
    /*********************************************/
    this.colorScoreColor = function(score) {
        if (score < 80) {
            return "good";
        } else if (score <= 90) {
	        return "better";
	    } else {
			return "best";
	    }
    },
	/*********************************************/
	this.launchTourLogic = function(tourData, slide) {
		$.ajax({
			url: '/ajax/display-tour.php',
			dataType : 'json',
			method: 'get',
			success: function(data){
				if(data.display_tour){
					hopscotch.startTour(countlessTour, slide);
				}
			}
		})
	},
	/*********************************************/
	this.showGuidedTour = function(tourData) {
		$.ajax({
			url: '/ajax/display-tour.php',
			dataType : 'json',
			data: {
				reset: true
			},
			method: 'post',
			success: function(data){
				if(data.is_reset){
					window.location.href = 'profile.php';
				}
			}
		})
	}
	/*********************************************/
}
$(function() {
	countless.init();
	$('.dismissable').append('<a href="#" class="dismiss"><i class="icon-x"></i></a>');
	$(document.body).delegate('.dismiss', 'click', function(){
		parent = $(this).parent();
		$.ajax({
		    url : 'ajax/message-dismiss.php',
		    type: 'post',
		    data : {
			    id: parent.attr('data-id')
		    },
			dataType : 'json',
		    success: function(data){
				if(data.result == 'success'){
					parent.slideUp();
				}
		    }
		});
	});
	$.ajax({
	  url: 'js/vendors/ZeroClipboard.min.js',
	  dataType: 'script',
	  cache: true,
	  success: function() {
			ZeroClipboard.config( { swfPath: "/js/vendors/ZeroClipboard.swf" } );
			var client = new ZeroClipboard( document.getElementById("copy-button") );
			client.on( "ready", function( readyEvent ) {
			client.on( "aftercopy", function( event ) {
			    $('#copy-button .popup').fadeIn(function(){
					setTimeout(function(){$('#copy-button .popup').fadeOut();}, 1000)
			    });
			  } );
			} );
	  }
	});
	$('#nav-right li').hover(function() {
		$(this).find('ul').fadeIn('fast');
	}, function() {
		$(this).find('ul').fadeOut('fast');
	})
	$('.display_prompt').prompt();
	// Form Validation
	$('.validate-form').on('submit', function(e) {
		var bubble_opts = {'side': ($(this).hasClass('validate-left') ? 'left' : 'right')};
		$.bubble('remove');
		var password_container = '';
		var valid = true;
		$(this).find('input, select').each(function() {
			if($(this).attr('type') == 'password') {
				if(password_container.length === 0) {
					password_container = $(this).val();
				} else if(password_container != $(this).val()){
					$(this).bubble(bubble_opts);
					valid = false;
				}
			}
			if ($(this).prev('label:contains(*)').length > 0 || $(this).attr('required') == 'required' || $(this).attr('data-required') == 'required') {
				$(this).attr('data-required', 'required');
				if (!$(this).val() || $(this).val().length == 0) {
					$(this).bubble(bubble_opts);
					valid = false;
				} else if ($(this).attr('type') == 'email' && !isValidEmailAddress($(this).val())) {
					$(this).bubble(bubble_opts);
					valid = false;
				} else if ($(this).hasClass('nonzero') && $(this).val() <= 0){
					$(this).bubble(bubble_opts);
					valid = false;
				}
			}
		});
		if(!valid){
			e.preventDefault();
		}

	});
	$('input').on('focus', function(){
		$(this).bubble('remove');
	});
	$(document).on('click', '.popup-menu, .add', function(e){
		e.stopPropagation();
	});
	$(document).on('click', function(){
		$('.popup-menu').remove();
	});
	$('#library-search, .no-submit').on( 'submit', function(e){
		e.preventDefault();
	} );

	$('.achievement').hover(function(){
		$(this).find('.label').stop().fadeIn();
	}, function(){
		$(this).find('.label').stop().fadeOut();
	});

	$(document).on('click', '.favorite', function(e){
		item = $(this).parents('li').data('item');
		e.preventDefault();
		if(item.favorite){
			item.favorite = !item.favorite;
			$('[data-itemid="' + item.id + '"]').data('item', item).find('.favorite').removeClass('active').children('.fa').removeClass('fa-star').addClass('fa-star-o');
		} else {
			item.favorite = !item.favorite;
			$('[data-itemid="' + item.id + '"]').data('item', item).find('.favorite').addClass('active').children('.fa').removeClass('fa-star-o').addClass('fa-star');
		}
		$.ajax({
		    url : 'ajax/food-favorite.php',
		    type: 'post',
		    data : {
			    id: item.id,
			    favorite: item.favorite
			    },
			dataType : 'json'
		});

	});

	/*********
		Mobile Nav
	*********/

	$('#mobile-nav-display').click(function(){
	  sidebar_width = $('#mobile-nav').width();
	  if($('#mobile-nav').css('right') == '0px'){
		  $('#site-wrapper').animate({'right': '-=' + sidebar_width + 'px'});
		  $('#mobile-nav').animate({'right': -sidebar_width + 'px'});
	  } else {
		 $('#site-wrapper').animate({'right': '+=' + sidebar_width + 'px'});
		  $('#mobile-nav').animate({'right': '0px'});
	  }
	});
	$('#x-icon').click(function(){$('#mobile-nav-display').click();});
});
