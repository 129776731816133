var waitForElementVisible = function(element, callback) {
    var checkExist = setInterval(function() {
        $element = typeof element == "function" ? $(element()) : $(element);
        if ($element.is(':visible')) {
            clearInterval(checkExist);
            if (typeof callback == "function") {
                callback();
            }
        }
    }, 100);
};
var nextOnCallback = function(callback, currentStep) {
    callback(function() {
        window.hopscotch.startTour(countlessTour, currentStep);
    });
};
var endTourSession = function(){
	$.ajax({
		url: 'ajax/display-tour.php',
		dataType: 'json',
		data: {
			end_tour: true
		},
		method: 'post'
	})
}
var countlessTour = {
	id: 'countless-tour',
	showPrevButton: true,
	skipIfNoElement: false,
	i18n: {
		prevBtn: 'Prev'
	},
	onEnd: endTourSession,
	onClose: endTourSession,
	steps: [
		{
			content: 'Welcome to the Countless program. We are going to walk you through the basics of Countless to help get you started. You can exit the tour at any time by clicking the x in the top right corner.<br /><br />Let\'s begin.',
			target: 'colorbar',
			placement: 'bottom',
			xOffset: 'center'
		},
		{
			content: 'This is your profile page. Here you can edit your account information and body metrics. Be sure to keep this information current, as Countless will automatically adjust your nutritional program based on your conditioning, weight, fitness level, and goals.',
			target: document.querySelectorAll('h1')[0],
			placement: 'bottom',
			showPrevButton: false,
			width: 350
		},
		{
			content: 'First, check your name and email address for accuracy. Then you can enter a cell phone number if you’d like to get text updates about your Countless account. Also, make sure to set your time zone to your local time.',
			target: document.querySelectorAll('#basic-contact')[0],
			placement: 'top'
		},
		{
			content: 'Next, you’ll need to submit information about your body metrics. Countless creates your personalized program based on the information provided. Enter your birthday, gender, current and goal weight, and choose an overall goal.',
			target: document.querySelectorAll('#personal-details')[0],
			placement: 'top'
		},
		{
			content: 'Finally, let us know your overall fitness level. Please choose the appropriate selection from the three drop-down menus.',
			target: document.querySelectorAll('#activity-details')[0],
			placement: 'top'
		},
		{
			content: 'Be sure to click the Save button.',
			target: document.querySelectorAll('button')[0],
			placement: 'top',
			nextOnTargetClick: true,
			showNextButton: false,
			multipage: true
		},
		{
			content: 'Now let’s go check out your Countless dashboard. Click next to continue to your dashboard.',
			target: document.querySelectorAll('#countless-logo')[0],
			placement: 'bottom',
			showPrevButton: false,
			onNext: function(){
				window.location.href = 'dashboard.php'
			},
			multipage: true
		},
		{
			content: 'This is your dashboard. It gives you information on your Countless program at a quick glance. <br /><br />This box shows how many meals you’ve consumed or planned for the day. The second number is your color score. Try to get a color score of 100 for a perfect day! This can be achieved by staying within your points and eating foods in the “best” category.',
			target: document.querySelectorAll('#day_summary h2')[1],
			placement: 'right',
			onPrev: function(){
				window.location.href = 'profile.php';
			}
		},
		{
			content: 'This box will show your meal plan for the day. Once you have entered your meals, they will appear here so you can easily see what you have planned. You can also edit your planned meals here, if necessary.',
			target: document.querySelectorAll('#panel-todaysmeals h2')[0],
			placement: 'top',

		},
		{
			content: 'This box shows your points remaining for the day. Countless isn’t about counting calories, it’s about making sure you eat a balanced diet. Your points are based on your weight, fitness level, activity level and will adjust accordingly as your profile is updated. Each food you consume will use up some of your points for the day. Try not to go over in any of the categories.',
			target: document.querySelectorAll('#panel-counts h2')[0],
			placement: 'left',
		},
		{
			content: 'You can quickly see and track your weight here. The graph will show both your current weight and goal weight. You can change either by clicking the update button. As you enter any changes to your weight, the graph will show your progress over time. Make sure to keep your current weight updated, as the program will adjust automatically as you enter your changes.',
			target: document.querySelectorAll('#panel-weightloss h2')[0],
			placement: 'left'
		},
		{
			content: 'Finally, the H2O calculator will show you how many cups of water you should be drinking each day. There is no need to record your water intake, just try and hit the suggested number of servings.<br /><br />Now lets go to your meals page.',
			target: document.querySelectorAll('#h2o h2')[0],
			placement: 'top',
			multipage: true,
			onNext: function(){
				window.location.href = 'meals.php'
			},
		},
		{
			content: 'This page shows your meals for the week. The current day will be automatically highlighted. Each day will also show the points you have remaining and your color score for that day. Try to make each day a Best day!',
			target: $('#week')[0],
			placement: 'top',
			width: 400,
			xOffset: 'center',
			onPrev: function(){
				window.location.href = 'dashboard.php';
			},
			multipage: true,
			onNext: function() {
	            nextOnCallback(function(callback) {
	              waitForElementVisible(function() {
	                return $('#add-today');
	              }, callback);
	            }, 13);
	        },
		},
		{
			content: 'Moving your cursor over the “+” button will give you two options: Log a regular meal or a cheat meal.<br /><br />You get one cheat meal per week. You can eat anything you want for your cheat meal, and you don’t need to record what you ate. But you only get one per week. Just be sure to mark your cheat meal as “used” afterwards.',
			target: '#add-today',
			placement: 'bottom',
			xOffset: -17,
			onPrev: function() {
	            window.hopscotch.startTour(countlessTour, window.hopscotch.getCurrStepNum());
	        },
	        onNext: function() {
	        	$('#add-today').mouseover();
	        }
		},
		{
			content: 'Click on the blue “add a meal” link to add your first meal.',
			target: '#add-today-meal',
			placement: 'bottom',
			nextOnTargetClick: true,
			showNextButton: false,
			multipage: true,
			delay: 250,
		},
		{
			content: 'Here you can create and edit your meals. As you add foods to your meal, Countless will automatically subtract the points each food uses.',
			target: '#this-meal-title',
			placement: 'top',
			onPrev: function(){
				window.location.href = 'meals.php';
			}
		},
		{
			content: 'These boxes on the right show both your total points remaining for the day, as well as how many points this meal has used.',
			target: '#points-remaining',
			placement: 'left'
		},
		{
			content: 'First, choose which meal you want to record.',
			target: '#meal-title',
			placement: 'right',
			yOffset: -20
		},
		{
			content: 'To add a food to your meal in the Countless program, you can search for a specific food in the box here. Or you can browse the food library, sorting foods by color rating and/or by category. <br /><br />Once you’ve found a food you want to add to your meal, you can drag it to your meal, or you can click the “+” button to add it to the meal. <br /><br />Choose a food and add it to your meal now.',
			target: '#query',
			placement: 'left',
			yOffset: -16
		},
		{
			content: 'If you want to change the amount of a specific food in your meal, you can use the + or – signs to increase or decrease the portions of that food in your meal.',
			target: '#meal-contents',
			placement: 'right'
		},
		{
			content: 'If you find that there is a food you are eating quite often, you can click the star to save it to your favorites. All of your favorite foods can be accessed quickly in the category drop-down, My Favorites.',
			target: '#meal-contents',
			placement: 'right'
		},
		{
			content: 'If there are specific meals you eat often, you can even save a whole meal to your Favorites menu. ',
			target: '#meal-favorite-link',
			placement: 'bottom',
			xOffset: '-20px'
		},
		{
			content: 'Click on the Moderation category in the drop-down menu.<br /><br />Moderation items are those little vices you can’t live without. We have added a few recommended moderation items for you. You can also create a custom moderation item.',
			target: '#query_category',
			placement: 'right',
			yOffset: -20,
			onNext: function(){
				$('#query_category').val(3).change();
			}
		},
		{
			content: 'First, enter a food name and the amount of calories. Then the program will automatically convert it to moderation points, and add it to your meal. It will also save the item you create in your moderation category for future use.',
			target: '#moderation_title',
			placement: 'left',
			yOffset: -16,
			delay: 250
		},
		{
			content: 'Can’t find a food you like? You can suggest a food to us and we might add it to the Countless program!',
			target: '#launch-add-modal',
			placement: 'left',
			yOffset: '-24px',
			xOffset: '-11px',
			onPrev: function(){
				$('#query_category').val(3).change();
			}
		},
		{
			content: 'Finally, if you want to see all the foods in the Countless program at a quick glance, you can do so here. Click the “view the food library” link.',
			target: '#food-library-link',
			placement: 'bottom',
			nextOnTargetClick: true,
			showNextButton: false,
			multipage: true
		},
		{
			content: 'Here it shows all the foods in the Countless program, along with their color rating. <br /><br />Now lets go look at achievements.',
			placement: 'top',
			multipage: true,
			yOffset: 20,
			target: '#library',
			onPrev: function(){
				window.history.go(-1);
			},
			onNext: function(){
				window.location.href = 'achievements.php';
			}
		},
		{
			content: 'To give you that added push to continue making good choices, Countless has developed an achievement system. Every time you make a great dietary choice, you have the potential to earn an achievement badge. These are little ways we reward you for all of your hard work.<br /><br />Finally, let’s check out the recipes section.',
			target: '.panel-plain',
			placement: 'bottom',
			multipage: true,
			onPrev: function(){
				window.location.href = 'food-library.php'
			},
			onNext: function(){
				window.location.href = 'recipes.php';
			}
		},
		{
			content: 'Looking for a meal suggestion? We’ve added some healthy recipes to get you started on your journey. If you’ve got a great recipe, click the blue “add a recipe” button and it could be added to the Countless program!',
			target: '#launch-add-recipe',
			placement: 'bottom',
			onPrev: function(){
				window.location.href = 'achievements.php';
			}
		},
		{
			content: 'And that wraps up your Countless tour. Head back into the Meals page and start planning out your meals. If you want to view this tour again, you can restart it from the link in your profile page. Good luck!',
			target: '#link-meals',
			placement: 'right'
		}
	]
};